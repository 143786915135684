/**
 * Check if the second date is after the first one or not
 * @function isSecondDateError
 * @param {string} dateOne First date
 * @param {string} dateTwo Second date
 * @returns {boolean}
 */
export default function isSecondDateError(dateOne, dateTwo) {
  if (!dateOne || !dateTwo) {
    return false;
  }
  const dateOneObj = new Date(dateOne);
  const dateTwoObj = new Date(dateTwo);
  return dateOneObj.getTime() > dateTwoObj.getTime();
}
