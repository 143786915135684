/** @module GeneralUIConstants  */

/** @constant {Object}
 *  @description React select component custom styles
 */
export const REACT_SELECT_STYLES = {
  control: provided => ({
    ...provided,
    borderRadius: "0.25rem",
    borderWidth: 0,
    borderColor: "transparent",
    boxShadow: "none",
    minHeight: 36,
    outline: 0,
  }),
  loadingIndicator: provided => ({
    ...provided,
    color: "#000",
  }),
  singleValue: provided => ({
    ...provided,
    fontWeight: "500",
  }),
};

/** @constant {Object}
 *  @description React select component custom styles used to make fixed options non-clearable
 */
export const REACT_SELECT_FIXED_OPTIONS_STYLES = {
  ...REACT_SELECT_STYLES,
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },
};

/** @constant {number}
 *  @default 20
 */
export const TABLE_DEFAULT_PAGE_SIZE = 20;

/** @constant {number[]}
 *  @default [20, 50, 100]
 */
export const TABLE_PAGE_SIZE = [20, 50, 100];
