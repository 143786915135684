import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SignUpLink() {
  const { t } = useTranslation();

  return (
    <div className="text-primary link-with-hover">
      <Link to="/sign-up">{t("sign_up")}</Link>
    </div>
  );
}

export default SignUpLink;
