function BladesSpinner({
  spinnerPosition,
  spinnerHeight,
  spinnerWidth,
  bladesColor,
  classes,
}) {
  return (
    <div
      className={`${spinnerPosition || "absolute"} top-0 left-0 ${
        spinnerWidth || "w-full"
      } ${spinnerHeight || "h-full"} flex items-center justify-center ${
        classes || ""
      }`}
    >
      <div className="relative w-[74px] h-74px">
        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade1`}
        ></div>

        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade2`}
        ></div>

        <div
          className={`spinner-blade ${bladesColor || "bg-primary"}
          } animate-blades sipnner-blade3`}
        ></div>

        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade4`}
        ></div>

        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade5`}
        ></div>

        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade6`}
        ></div>

        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade7`}
        ></div>

        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade8`}
        ></div>

        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade9`}
        ></div>

        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade10`}
        ></div>

        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade11`}
        ></div>

        <div
          className={`spinner-blade ${
            bladesColor || "bg-primary"
          } animate-blades sipnner-blade12`}
        ></div>
      </div>
    </div>
  );
}

export default BladesSpinner;
