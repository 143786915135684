import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { useTenantsGlobalStore } from "../../../../tenantsManagementStore/TenantsGlobalStoreProvider";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import tenantController from "../../../../services/APIsConfig/tenantController";
import { useEffect } from "react";

function RequestsLink() {
  const {
    requestCount,
    updateRequestCount,
    refreshRequestCount,
    updateRefreshRequestCount,
  } = useTenantsGlobalStore();
  const { axiosFetch: getNewRequestCount } = useAxiosFunction();

  useEffect(() => {
    if (refreshRequestCount) {
      const configObj = tenantController.getNewRequestCountConfig();
      const responseHandler = responseData => {
        updateRefreshRequestCount(false);
        updateRequestCount(responseData);
      };
      getNewRequestCount(configObj, responseHandler);
    }
  }, [refreshRequestCount]);

  return (
    <NavLink
      to="/requests"
      className="pages-navbar-link relative"
      activeClassName="pages-navbar-active-link"
    >
      <FontAwesomeIcon icon={faBell} className="mr-1.5" />
      <span className="mr-1.5">Requests</span>
      <span
        className={`text-[10px] leading-4 inline-block text-center w-7 text-white rounded shadow-md ${
          requestCount > 0 ? "bg-warning" : "bg-primary"
        }`}
      >
        {requestCount > 99 ? "+99" : requestCount}
      </span>
    </NavLink>
  );
}

export default RequestsLink;
