import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import reactDom from "react-dom";
import { useTranslation } from "react-i18next";

function ErrorOverlay({ resetErrorBoundary }) {
  const { t } = useTranslation();

  return (
    <div className="text-primary fixed z-999 left-0 top-0 bg-black text-xl flex flex-col items-center justify-center w-full h-full p-2 font-bold tracking-wide">
      <img
        className="w-32"
        src="/assets/images/support/support.png"
        alt="connect to support"
      />

      <h1 className="font-bold text-3xl my-5">{t("system_error_occurred")}</h1>

      <div className="text-white font-semibold text-sm text-center">
        <p className="text-lg mb-5">{t("system_error_msg")}</p>
        <p>
          {t("system_error_guidance_part1")}
          <a
            href="https://turnsole.tech/"
            className="mx-1 text-link hover:text-opacity-95"
          >
            Turnsole
          </a>
          {t("system_error_guidance_part2")}
        </p>
      </div>

      <button
        className="absolute top-10 right-10 scale-on-hover"
        onClick={resetErrorBoundary}
      >
        <FontAwesomeIcon icon={faTimesCircle} size="2x" />
      </button>
    </div>
  );
}

function ErrorFallback(props) {
  return (
    <>
      {reactDom.createPortal(
        <ErrorOverlay {...props} />,
        document.getElementById("error-overlay")
      )}
    </>
  );
}

export default ErrorFallback;
