import i18next from "i18next";
import Swal from "sweetalert2";

/**
 * Shows an information alert popup modal
 * @function showInfoMessage
 * @async
 * @param {string} title - Alert title
 * @param {string} message - Alert message
 * @param {Function} [confirmedFunction] - Execute it if it has passed and the alert has confirmed
 */
export default async function showInfoMessage(
  title,
  message,
  confirmedFunction
) {
  const t = i18next.t;
  await Swal.fire({
    title: title,
    html: message,
    icon: "info",
    iconColor: "#303F9F",
    confirmButtonColor: "#303F9F",
    confirmButtonText: t("ok"),
    customClass: {
      icon: "animate-pulse",
      title: "leading-8",
    },
  });

  if (confirmedFunction) {
    confirmedFunction();
  }
}
