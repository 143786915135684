/** @module DataCollectionConstants */

/** @constant {string[]}
 *  @default [".pdf", ".doc", ".docx", ".xls", ".xlsx"]
 */
export const FILE_QUESTION_TYPES = [".pdf", ".doc", ".docx", ".xls", ".xlsx"];

/** @constant {string[]}
 *  @default [".pdf", ".doc", ".docx", ".xls", ".xlsx"]
 */
export const FILE_QUESTION_TYPES_MIME = [
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

/** @constant {Object}
 *  @default {
  previewDesign: "previewDesign",
  interview: "interview",
  campaignInterview: "campaignInterview",
  previewCampaignInterview: "previewCampaignInterview",
  editInterview: "editInterview",
  editCampaignInterview: "editCampaignInterview",
  editViewInterview: "editViewInterview",
  editAnonymousInterview: "editAnonymousInterview",
}
 */
export const DATA_COLLECTION_MODE = {
  previewDesign: "previewDesign",
  interview: "interview",
  campaignInterview: "campaignInterview",
  previewCampaignInterview: "previewCampaignInterview",
  editInterview: "editInterview",
  editCampaignInterview: "editCampaignInterview",
  editViewInterview: "editViewInterview",
  editAnonymousInterview: "editAnonymousInterview",
};
