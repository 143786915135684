import Swal from "sweetalert2";

/**
 * Show a closable toast alert using Swal (SweetAlert2).
 * @function showClosableToastAlert
 * @param {string} iconType - The type of icon to display in the toast alert. Can be one of "success", "error", "warning", "info", or "question".
 * @param {string} title - The title to display in the toast alert.
 * @param {string} [message] - The message to display in the toast alert.
 * @returns {void} - This function does not return anything.
 */
export default function showClosableToastAlert(iconType, title, message = "") {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showCloseButton: true,
    showConfirmButton: false,
    showClass: { popup: "swal2-show" },
    hideClass: { popup: "swal2-hide" },
  });
  Toast.fire({
    icon: iconType,
    title: title,
    text: message,
  });
}
