import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { components } from "react-select";

function DropdownIndicator(props) {
  const selectProps = props.selectProps;
  const isOpen = selectProps?.menuIsOpen;

  return (
    <components.DropdownIndicator {...props}>
      {!isOpen && <CaretDownOutlined className="text-[#0000008a]" />}
      {isOpen && <CaretUpOutlined className="text-[#0000008a]" />}
    </components.DropdownIndicator>
  );
}

export default DropdownIndicator;
