import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { REACT_SELECT_STYLES } from "../../../../lib/Constants/GeneralUIConstants";
import DropdownIndicator from "../../../UI/ReactSelectWidgets/DropdownIndicator";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import checkLanguageCodeValidity from "../../../../utils/locals/checkLanguageCodeValidity";
import changeWebsiteLanguage from "../../../../utils/locals/changeWebsiteLanguage";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import settingsController from "../../../../services/APIsConfig/settingsController";

function LanguageFormControl() {
  const [progressing, setProgressing] = useState(false);
  const [selectedLang, setSelectedLang] = useState(null);

  const {
    response: loadedLangs,
    setResponse: updateLoadedLangs,
    axiosFetch: getActiveLanguages,
  } = useAxiosFunction([], setProgressing);

  const { t, i18n } = useTranslation();
  const dir = i18n?.dir();
  const isLtr = dir === "ltr";

  const { control } = useFormContext();

  const handleError = useErrorHandler();

  const changeLanguageHandler = async (e, onChange) => {
    try {
      const langCode = e.value;
      const langDir = e.dir;
      const isChangedSuccessfully = await changeWebsiteLanguage(
        langCode,
        langDir
      );
      if (isChangedSuccessfully) {
        onChange(e);
        setSelectedLang(e);
      }
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    setProgressing(true);
    const configObj = settingsController.getActiveLanguagesConfig();
    const responseHandler = responseData => {
      const loadedLanguages = responseData.res;
      if (loadedLanguages?.length === 0) {
        return;
      }
      updateLoadedLangs(loadedLanguages);
      const storedLanguageCode = i18n.resolvedLanguage;
      let currentUserLang = loadedLanguages.find(
        loadedLanguage => loadedLanguage.value === "en"
      );
      if (checkLanguageCodeValidity(storedLanguageCode)) {
        const langInfo = loadedLanguages.find(
          loadedLanguage => loadedLanguage.value === storedLanguageCode
        );
        currentUserLang = langInfo;
      }
      if (currentUserLang) {
        setSelectedLang(currentUserLang);
        const langCode = currentUserLang.value;
        const langDir = currentUserLang.dir;
        if (storedLanguageCode !== langCode) {
          changeWebsiteLanguage(langCode, langDir);
        } else {
          changeWebsiteLanguage(langCode, langDir, true);
        }
      }
      setProgressing(false);
    };
    getActiveLanguages(configObj, responseHandler);
  }, []);

  return (
    <div className="text-main-gray dark:text-dark-main-white flex items-center gap-3 my-5">
      <label className="text-base font-bold shrink-0">
        {t("select_language")}
      </label>
      <Controller
        control={control}
        name="language"
        value={selectedLang}
        render={({ field: { onChange } }) => (
          <Select
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            styles={REACT_SELECT_STYLES}
            className="grow rounded border border-gray-ccc text-sm text-gray-555 focus:ring-0 focus:border-gray-ccc"
            onChange={e => changeLanguageHandler(e, onChange)}
            options={loadedLangs}
            value={selectedLang}
            isClearable={false}
            isSearchable={true}
            menuPlacement="auto"
            isRtl={!isLtr}
            progressing={progressing}
            placeholder={t("select")}
            noOptionsMessage={() => t("no_options")}
          />
        )}
      />
    </div>
  );
}

export default LanguageFormControl;
