import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoginFormControls from "./LoginFormControls";
import LanguageFormControl from "./LanguageFormControl";
import { FormProvider, useForm } from "react-hook-form";
import BladesSpinner from "../../../UI/Loaders/BladesSpinner";
import axios from "axios";
import APIErrorResponseAlert from "../../../UI/Errors/APIErrorResponseAlert";
import { useErrorHandler } from "react-error-boundary";
import { useAuth } from "../../../../store/AuthProvider";
import { useTranslation } from "react-i18next";
import { ERROR_MSG_TIMEOUT } from "../../../../lib/Constants/GlobalConstants";
import accountController from "../../../../services/APIsConfig/accountController";
import showClosableToastAlert from "../../../../utils/alerts/showClosableToastAlert";

function LoginForm() {
  const [progressing, setProgressing] = useState(false);
  const [error, setError] = useState(null);

  const authCtx = useAuth();

  const isUserLoggedIn = authCtx?.isLoggedIn;

  const handleError = useErrorHandler();
  const { t } = useTranslation();

  const history = useHistory();

  const methods = useForm();

  const clearError = () => {
    setError(null);
  };

  const loginSubmitHandler = data => {
    setError(null);
    const sendingData = {
      email: data.email,
      password: data.password,
      rememberMe: data.rememberMe,
    };
    setProgressing(true);
    const { method, url } = accountController.loginConfig();
    axios[method.toLowerCase()](url, sendingData)
      .then(function (response) {
        setProgressing(false);
        const loadedTokenInfo = {
          token: response.data.access_token || "Error",
          refreshToken: response.data.refreshToken || "Error",
          expiresIn: response.data.expires_in || "Error",
          userRole: response.data.token_user_role || "",
          userRoles: response.data.token_user_roles || [],
          userFullName: response.data.token_user_fullname || "",
          userName: response.data.token_user_username || "",
          userEmail: response.data.token_user_usermail || "",
          userId: response.data.token_user_Id || "UserId not found!",
          userMobileNumber: response.data.token_user_MobileNumber || "",
          userHometown: response.data.token_user_hometown || "",
          userPermissions: response.data.token_permissions_roles || [],
          message: response.data.message || "No message",
          gracePeriodStartDate: response.data.gracePeriodStartDate,
          // rememberMe: data.rememberMe,
        };
        authCtx.login(loadedTokenInfo);
      })
      .catch(function (error) {
        setProgressing(false);
        if (error.response) {
          if ([460, 461].includes(error.response.status)) {
            history.push("/confirm-account", {
              userData: error.response.data.targetObject,
            });
          } else if (
            error.response.status >= 400 &&
            error.response.status < 500
          ) {
            setError(error.response?.data?.message);
          } else {
            showClosableToastAlert(
              "error",
              t("we_are_sorry_something_went_wrong")
            );
          }
        } else if (error.request) {
          setError(t("please_check_network_connection_and_try_again"));
        } else {
          handleError(error);
        }
      });
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      history.replace("/");
    }
  }, [isUserLoggedIn, history]);

  return (
    <FormProvider {...methods}>
      <form
        className="mb-6"
        noValidate
        onSubmit={methods.handleSubmit(loginSubmitHandler)}
      >
        {progressing && (
          <BladesSpinner classes="z-1 bg-white dark:bg-dark-1e" />
        )}

        <APIErrorResponseAlert
          errorMessage={error}
          clearError={clearError}
          timerValue={ERROR_MSG_TIMEOUT}
        />

        <LanguageFormControl />

        <LoginFormControls />

        <div className="text-center">
          <button className="w-full py-1 transition-all duration-200 font-semibold border-3px border-primary bg-primary/90 rounded text-white hover:text-primary hover:bg-gray-100">
            {t("login")}
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

export default LoginForm;
