import * as Sentry from "@sentry/react";

const sentryDsn = window.__ENV__.SENTRY_DSN;
const environment = window.__ENV__.ENVIRONMENT;
const enabledSentry = window.__ENV__.ENABLED_SENTRY;
const releaseNumber = window.__ENV__.RELEASE_NUMBER;
const tracePropagationTargets = window.__ENV__.TRACE_TARGETS || [];

if (sentryDsn) {
  Sentry.init({
    enabled: enabledSentry,
    debug: import.meta.env.MODE !== "production",
    dsn: sentryDsn,
    release: `BlueData@${releaseNumber}`,
    environment: environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    attachStacktrace: true,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
