import axios from "axios";
import checkLanguageCodeValidity from "./checkLanguageCodeValidity";
import i18next from "i18next";

/**
 * Changes the website's language and text direction.
 * @function changeWebsiteLanguage
 * @async
 * @param {string} langCode - The language code to change to.
 * @param {string} langDir - The text direction ('ltr' for left-to-right, 'rtl' for right-to-left).
 * @param {boolean} [changeAttributesOnly = false] - Change html tag attributes only
 * @returns {boolean} Returns true if the language changed successfully
 */
export default async function changeWebsiteLanguage(
  langCode,
  langDir,
  changeAttributesOnly = false
) {
  const langCodeValidity = checkLanguageCodeValidity(langCode);

  const changeAttributes = (langCode, langDir) => {
    const htmlElement = window.document.documentElement;
    htmlElement.lang = langCode;
    htmlElement.dir = langDir;

    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      "Accept-Language": langCode,
    };
  };

  if (langCodeValidity) {
    let isChangedSuccessfully = false;
    if (!changeAttributesOnly) {
      await i18next.reloadResources([langCode]);
    }
    changeAttributes(langCode, langDir);
    await i18next.changeLanguage(langCode, err => {
      if (err) {
        console.warn(`Could not change to the language ${langCode}: ${err}`);
        changeAttributes("en", "ltr");
        localStorage.setItem("i18nextLng", "en");
        isChangedSuccessfully = false;
      } else {
        isChangedSuccessfully = true;
      }
      document.title = i18next.t("app_title");
    });
    return isChangedSuccessfully;
  } else {
    console.warn("Invalid language code: ", langCode);
    return false;
  }
}
