import { useFormContext } from "react-hook-form";
import ErrorTooltip from "../../UI/Errors/ErrorTooltip";
import { useTranslation } from "react-i18next";

function AuthInputControl(props) {
  const { inputType, placeholderText, registerName, validationRules } = props;

  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <div className="relative">
        {props.children}
        <input
          className={`w-full px-8 focus:ring-0 rounded border text-sm text-gray-555 ${
            errors[registerName]
              ? "border-warning focus:border-warning"
              : "border-gray-ccc focus:border-primary"
          }`}
          type={inputType}
          placeholder={placeholderText}
          autoComplete="off"
          {...register(registerName, validationRules)}
          onFocus={e => {
            e.target.select();
          }}
        />
      </div>

      {errors[registerName] && (
        <>
          {errors[registerName]?.type !== "notEmpty" && (
            <ErrorTooltip message={errors[registerName]?.message} />
          )}
          {errors[registerName]?.type === "notEmpty" && (
            <ErrorTooltip message={t("required_message")} />
          )}
        </>
      )}
    </div>
  );
}

export default AuthInputControl;
