/** @module AuditsConstants */

/** @constant {Object[]}
 *  @discrption Is Archive select list options
 */
export const IS_ARCHIVED_OPTIONS = [
  { value: "All", label: "All" },
  { value: "Archived", label: "Archived" },
  { value: "Not Archived", label: "Not Archived" },
];

/** @constant {string[]}
 */
export const OBJECT_TYPE = [
  "ApplicationUser",
  "Activity",
  "ActivityLocation",
  "ActivityUser",
  "CriteriaHeader",
  "CriteriaLine",
  "Location",
  "Project",
  "ProjectLanguage",
  "ProjectLocation",
  "ProjectUser",
  "ResourceTranslation",
  "Survey",
  "SurveyAnswer",
  "SurveyLanguage",
  "SurveyLocation",
  "Task",
  "UserLocation",
  "UserInterview",
  "Notification",
  "Audit",
  "CriteriaType",
  "CriteriaTypeDetail",
  "PermissionApplicationRoles",
  "SurveyDetails",
  "Language",
  "Sector",
  "PCode",
  "Currency",
];

/** @constant {Object[]}
 *  @discrption Object type select list options
 */
export const OBJECT_TYPE_SELECT_OPTIONS = OBJECT_TYPE.map(objectType => {
  return {
    label: objectType,
    value: objectType,
  };
});

/** @constant {string[]}
 *  @default ["Update", "Delete"]
 */
export const ACTION_TYPES_CAN_UNDO_IT = ["Update", "Delete"];

/** @constant {string[]}
 *  @default ["Add","Update","Delete","UndoUpdate","UndoDelete","Archive","Get","Download","Upload"]
 */
export const ACTION_TYPE = [
  "Add",
  "Update",
  "Delete",
  "UndoUpdate",
  "UndoDelete",
  // "Archive",
  // "Get",
  // "Download",
  // "Upload",
];

/** @constant {Object[]}
 *  @discrption Action type select list options
 */
export const ACTION_TYPE_SELECT_OPTIONS = ACTION_TYPE.map(actionType => {
  return {
    label: actionType,
    value: actionType,
  };
});
