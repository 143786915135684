import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ErrorTooltip(props) {
  const { message, iconClasses } = props;

  return (
    <div className="relative w-full group">
      <div
        className={iconClasses || `absolute z-1 -top-7.5 ltr:right-3 rtl:left-3`}
      >
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-warning"
        />
      </div>
      <div className="hidden group-hover:block w-full absolute top-1">
        <div className="relative w-full flex flex-col items-center">
          <div className="w-3 h-3 absolute -top-1.5 z-20 transform rotate-[45deg] bg-[#fff3f3] border-l-2 border-t-2 border-warning border-solid ltr:left-10 rtl:right-10"></div>
          <span className="w-full relative z-10 px-3 py-1.5 text-sm font-semibold text-warning whitespace-no-wrap bg-[#fff3f3] rounded border-2 border-warning border-solid shadow-md">
            {message}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ErrorTooltip;
