import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ForgetLink() {
  const { t } = useTranslation();

  return (
    <div className="text-warning link-with-hover">
      <Link to="/forgot-password">{t("forget_password")}</Link>
    </div>
  );
}

export default ForgetLink;
