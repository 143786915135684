/**
 * Generates URLs for redirect pages based on the current domain.
 * @returns {Object} - An object containing URLs for various redirect pages.
    accessDeniedPageUrl - The URL for the access denied page.
    subscriptionProblemPageUrl - The URL for the subscription problem page.
    manageSubscriptionPageUrl - The URL for the manage subscription page.
 */

export function getURLOfRedirectPages() {
  const domainName = window.location.protocol + "//" + window.location.host;
  const accessDeniedPageUrl = domainName + "/access-denied";
  const subscriptionProblemPageUrl = domainName + "/subscription-problem";
  const manageSubscriptionPageUrl = domainName + "/manage-subscription";
  return {
    accessDeniedPageUrl,
    subscriptionProblemPageUrl,
    manageSubscriptionPageUrl,
  };
}
