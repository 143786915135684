import { NavLink } from "react-router-dom";
import {
  faAward,
  faFileContract,
  faUserShield,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTenantsAuth } from "../../../../tenantsManagementStore/TenantsAuthProvider";
import RequestsLink from "./RequestsLink";

function PagesNavbar() {
  const { logout } = useTenantsAuth();

  return (
    <div className="flex items-center gap-8">
      <FontAwesomeIcon icon={faUserShield} className="text-primary" size="3x" />
      <nav className="flex items-center gap-3">
        <NavLink
          to="/subscribers"
          className="pages-navbar-link"
          activeClassName="pages-navbar-active-link"
        >
          <FontAwesomeIcon icon={faUsers} className="mr-1.5" />
          <span>Subscribers</span>
        </NavLink>
        <RequestsLink />
        <NavLink
          to="/contracts-management"
          className="pages-navbar-link"
          activeClassName="pages-navbar-active-link"
        >
          <FontAwesomeIcon icon={faFileContract} className="mr-1.5" />
          <span>Contracts Management</span>
        </NavLink>
        <NavLink
          to="/bundles-management"
          className="pages-navbar-link"
          activeClassName="pages-navbar-active-link"
        >
          <FontAwesomeIcon icon={faAward} className="mr-1.5" />
          <span>Bundles Management</span>
        </NavLink>

        <button onClick={logout} className="ml-5 font-semibold text-warning">
          Log Out
        </button>
      </nav>
    </div>
  );
}

export default PagesNavbar;
