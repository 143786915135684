import UserAuthentication from "../../components/UserAuthentication/UserAuthentication";
import UserLogin from "../../components/UserAuthentication/Login/UserLogin";

function Login() {
  return (
    <UserAuthentication>
      <UserLogin />
    </UserAuthentication>
  );
}

export default Login;
