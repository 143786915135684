import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import MainLogo from "../components/UI/Logos/MainLogo";

function CompletionOfPayment() {
  const { t } = useTranslation();

  const { search } = useLocation();
  const searchParams = search ? new URLSearchParams(search) : null;
  const redirectStatus = searchParams?.get("redirect_status");

  return (
    <div className="relative w-full h-screen flex flex-col items-center justify-center bg-gray-100 dark:bg-dark-1e">
      <MainLogo classes="w-24 absolute ltr:left-4 rtl:right-4 top-1" />
      <FontAwesomeIcon
        icon={faCreditCard}
        size="2xl"
        className="text-primary"
      />
      <p className="mt-3 mb-1 font-bold text-lg capitalize text-primary">
        {t("thank_you")}
      </p>
      {redirectStatus && (
        <p className="text-center flex dark:text-dark-main-white">
          <span className="ltr:pr-1 rtl:pl-1 capitalize">{t("payment")}</span>
          <span className="capitalize">{t(redirectStatus)}</span>
        </p>
      )}
    </div>
  );
}

export default CompletionOfPayment;
