const controllerName = "/Tenant";

export default {
  loginConfig: () => {
    return {
      method: "post",
      url: `${controllerName}/Login`,
    };
  },
  signUpConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/SignUp`,
      data: payload,
    };
  },
  changeApprovalStatusForTenantConfig: (tenantId, approvalStatus) => {
    return {
      method: "post",
      url: `${controllerName}/ChangeApprovalStatusForTenant?tenantId=${tenantId}&approvalStatus=${approvalStatus}`,
    };
  },
  changeSuspendStatusConfig: (tenantId, isSuspended) => {
    return {
      method: "post",
      url: `${controllerName}/ChangeSuspendStatus?tenantId=${tenantId}&isSuspended=${isSuspended}`,
    };
  },
  updateEndDateConfig: (tenantId, newDate) => {
    return {
      method: "post",
      url: `${controllerName}/UpdateEndDate?tenantId=${tenantId}&newDate=${newDate}`,
    };
  },
  updateUsageMaxValueConfig: (tenantId, usageType, newValue) => {
    return {
      method: "post",
      url: `${controllerName}/UpdateUsageMaxValue?tenantId=${tenantId}&usageType=${usageType}&newValue=${newValue}`,
    };
  },
  changeRequestStatusConfig: (requestId, status) => {
    return {
      method: "post",
      url: `${controllerName}/ChangeRequestStatus?requestId=${requestId}${
        status === 0 || status ? `&status=${status}` : ""
      }`,
    };
  },
  createContractConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/CreateContract`,
      data: payload,
    };
  },
  createBundleConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/CreateBundle`,
      data: payload,
    };
  },
  updateContractConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateContract`,
      data: payload,
    };
  },
  updateBundleConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateBundle`,
      data: payload,
    };
  },
  contractRequestConfig: contractId => {
    return {
      method: "post",
      url: `${controllerName}/ContractRequest?contractId=${contractId}`,
    };
  },
  bundleRequestConfig: bundleId => {
    return {
      method: "post",
      url: `${controllerName}/BundleRequest?bundleId=${bundleId}`,
    };
  },
  unlockTenantConfig: tenantId => {
    return {
      method: "post",
      url: `${controllerName}/UnlockTenant?tenantId=${tenantId}`,
    };
  },
  changeContractPublishStatusConfig: (contractId, isPublished) => {
    return {
      method: "post",
      url: `${controllerName}/ChangeContractPublishStatus?contractId=${contractId}&isPublished=${isPublished}`,
    };
  },
  changeBundlePublishStatusConfig: (bundleId, isPublished) => {
    return {
      method: "post",
      url: `${controllerName}/ChangeBundlePublishStatus?bundleId=${bundleId}&isPublished=${isPublished}`,
    };
  },
  sendConfirmSubscriptionEmailConfig: () => {
    return {
      method: "post",
      url: `${controllerName}/SendConfirmSubscriptionEmail`,
    };
  },
  deleteContractConfig: contractId => {
    return {
      method: "delete",
      url: `${controllerName}/DeleteContract?contractId=${contractId}`,
    };
  },
  deleteBundleConfig: bundleId => {
    return {
      method: "delete",
      url: `${controllerName}/DeleteBundle?bundleId=${bundleId}`,
    };
  },
  verifyEmailAddressConfig: token => {
    return {
      method: "get",
      url: `${controllerName}/VerifyEmailAddress?token=${token}`,
    };
  },
  checkEmailAvailabilityConfig: email => {
    return {
      method: "get",
      url: `${controllerName}/CheckEmailAvailability?email=${email}`,
    };
  },
  checkServerNameAvailabilityConfig: name => {
    return {
      method: "get",
      url: `${controllerName}/CheckServerNameAvailability?name=${name}`,
    };
  },
  getTenantsConfig: (
    skip,
    limit,
    signUpDate = null,
    endDate = null,
    status
  ) => {
    return {
      method: "get",
      url: `${controllerName}/GetTenants?skip=${skip}&limit=${limit}${
        signUpDate ? `&signUpDate=${signUpDate}` : ""
      }${endDate ? `&endDate=${endDate}` : ""}${
        status === 0 || status ? `&status=${status}` : ""
      }`,
    };
  },
  getTenantContractsConfig: (
    tenantId,
    skip,
    limit,
    startDate = null,
    endDate = null,
    contractType,
    contractStatus
  ) => {
    return {
      method: "get",
      url: `${controllerName}/GetTenantContracts?tenantId=${tenantId}&skip=${skip}&limit=${limit}${
        startDate ? `&startDate=${startDate}` : ""
      }${endDate ? `&endDate=${endDate}` : ""}${
        contractType === 0 || contractType
          ? `&contractType=${contractType}`
          : ""
      }${
        contractStatus === 0 || contractStatus
          ? `&contractStatus=${contractStatus}`
          : ""
      }`,
    };
  },
  getTenantBundlesConfig: (
    tenantId,
    skip,
    limit,
    startDate = null,
    endDate = null,
    type,
    bundleStatus
  ) => {
    return {
      method: "get",
      url: `${controllerName}/GetTenantBundles?tenantId=${tenantId}&skip=${skip}&limit=${limit}${
        startDate ? `&startDate=${startDate}` : ""
      }${endDate ? `&endDate=${endDate}` : ""}${
        type === 0 || type ? `&type=${type}` : ""
      }${
        bundleStatus === 0 || bundleStatus
          ? `&bundleStatus=${bundleStatus}`
          : ""
      }`,
    };
  },
  getTenantsRequestsConfig: (skip, limit, requestDate = null, type, status) => {
    return {
      method: "get",
      url: `${controllerName}/GetTenantsRequests?skip=${skip}&limit=${limit}${
        requestDate ? `&requestDate=${requestDate}` : ""
      }${type === 0 || type ? `&type=${type}` : ""}${
        status === 0 || status ? `&status=${status}` : ""
      }`,
    };
  },
  getUsageDetailsConfig: tenantId => {
    return {
      method: "get",
      url: `${controllerName}/GetUsageDetails?tenantId=${tenantId}`,
    };
  },
  getTenantReportConfig: () => {
    return {
      method: "get",
      url: `${controllerName}/GetTenantReport`,
    };
  },
  getContractsConfig: () => {
    return {
      method: "get",
      url: `${controllerName}/GetContracts`,
    };
  },
  getBundlesConfig: () => {
    return {
      method: "get",
      url: `${controllerName}/GetBundles`,
    };
  },
  getNewRequestCountConfig: () => {
    return {
      method: "get",
      url: `${controllerName}/GetNewRequestCount`,
    };
  },
};
