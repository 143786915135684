import LogoShape from "../UI/Logos/LogoShape";

function UserAuthentication(props) {
  return (
    <section className="min-h-screen flex items-center ltr:bg-ltr-login-background rtl:bg-rtl-login-background bg-no-repeat bg-center bg-cover py-16 pb-10">
      <div className="hidden dark:block absolute left-0 top-0 w-full h-full bg-black/40" />
      <main className="relative z-1 flex flex-wrap items-start justify-center  w-480px bg-white dark:bg-dark-1e rounded opacity-60 hover:opacity-100 transition-opacity duration-300 ease-in-out shadow-xl ltr:ml-[5%] ltr:2xl:ml-[10%] rtl:mr-[5%] rtl:2xl:mr-[10%]">
        <div className="relative -top-16 border-[10px] border-primary bg-white dark:bg-dark-1e rounded-full overflow-hidden w-32 h-32">
          <LogoShape classes="w-32 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        </div>
        <div className="relative -top-12 w-10/12">{props.children}</div>
      </main>
    </section>
  );
}

export default UserAuthentication;
