import { getSubdomainName } from "./getSubdomainName";

/**
 * Check if the current subdomain corresponds to an RM Team domain.
 * @function checkRMTeamDomain
 * This function retrieves the current subdomain using the `getSubdomainName` function and checks whether it matches known RM Team subdomains. It returns a boolean indicating whether the current subdomain is associated with RM Team.
 * @returns {boolean} - `true` if the current subdomain is an RM Team domain, otherwise `false`.
 */
export function checkRMTeamDomain() {
  const subdomain = getSubdomainName();
  const isRMTeam = ["", "rmteam"].includes(subdomain);
  return isRMTeam;
}
