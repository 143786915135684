import { getSubdomainName } from "../../../utils/getSubdomainName";
import ForgetLink from "./ForgetLink";
import LoginForm from "./LoginForm/LoginForm";
import SignUpLink from "./SignUpLink";

function UserLogin() {
  const showSignUpLink = getSubdomainName() === "";

  return (
    <>
      <LoginForm />
      <div className="flex justify-between gap-3">
        <ForgetLink />
        {showSignUpLink && <SignUpLink />}
      </div>
    </>
  );
}

export default UserLogin;
