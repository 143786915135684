import { getSubdomainName } from "./getSubdomainName";

/**
 * Get the backend API URL based on the current subdomain.
 * @function getBackendApiUrl
 * @returns {string} - The adjusted backend API URL for the current subdomain.
 */
export function getBackendApiUrl() {
  try {
    const API_URL = window.__ENV__.API;
    const subdomain = getSubdomainName();
    const convertedApiUrl =
      API_URL?.replace(subdomain ? "subdomain" : "subdomain.", subdomain) || "";
    return convertedApiUrl;
  } catch (err) {
    console.warn(
      `Could not generate backend API from this subdomain ${window.location.hostname}`
    );
  }
}
