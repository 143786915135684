import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useFormContext } from "react-hook-form";
import ErrorTooltip from "../../UI/Errors/ErrorTooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function PasswordInputControl(props) {
  const { inputType, placeholderText, registerName, validationRules } = props;

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="w-full">
      <div className="w-full relative">
        {props.children}
        <input
          className={`w-full px-8 focus:ring-0 rounded border border-gray-ccc text-sm text-gray-555 ${
            errors[registerName]
              ? "border-warning focus:border-warning"
              : "border-gray-555 focus:border-primary"
          }`}
          type={passwordVisibility ? "text" : inputType}
          placeholder={placeholderText}
          autoComplete="off"
          {...register(registerName, validationRules)}
          onFocus={e => {
            e.target.select();
          }}
        />
        {!errors[registerName] && (
          <button
            onClick={() => setPasswordVisibility(prevState => !prevState)}
            type="button"
            className="absolute top-1/2 transform -translate-y-1/2 ltr:right-3 rtl:left-3"
          >
            {passwordVisibility && <FontAwesomeIcon icon={faEye} />}
            {!passwordVisibility && <FontAwesomeIcon icon={faEyeSlash} />}
          </button>
        )}
      </div>

      {errors[registerName] && (
        <ErrorTooltip message={t(errors[registerName]?.message || "")} />
      )}
    </div>
  );
}

export default PasswordInputControl;
