import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

function APIErrorResponseAlert(props) {
  const { errorMessage, clearError, classes, timerValue } = props;

  useEffect(() => {
    let timeoutId = null;
    if (errorMessage) {
      timeoutId = setTimeout(clearError, timerValue);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [errorMessage, clearError]);

  return (
    <div
      className={`${classes || ""} ${
        errorMessage
          ? "text-center opacity-100 bg-warning text-white font-semibold rounded animate-pulse py-1 px-2 flex justify-center"
          : "opacity-0"
      }`}
    >
      <span className="mx-1">
        <FontAwesomeIcon icon={faExclamationCircle} size="sm" /> {errorMessage}
      </span>
    </div>
  );
}

export default APIErrorResponseAlert;
