/** @module PermissionsHelpers */

const generalSectionInfo = {
  id: "general",
  sectionTitle: "General Permissions",
  attributes: [
    {
      value: "ViewGeneralDashboard",
      details: ["Preview general dashboard"],
    },
    {
      value: "GlobalSettingsManagement",
      details: ["Global settings"],
    },
    {
      value: "CollectData",
      details: ["Data collection"],
    },
  ],
};

const projectsSectionInfo = {
  id: "projects",
  sectionTitle: "Projects",
  attributes: [
    {
      value: "PreviewProjects",
      details: ["Preview projects", "Preview project users"],
    },
    {
      value: "ViewProjectDashboard",
      details: ["Preview project dashboard"],
    },
    {
      value: "CreateProject",
      details: ["Create new project"],
    },
    {
      value: "UpdateProject",
      details: [
        "Edit project",
        "Close project",
        "Add user to project",
        "Edit project user",
        "Delete project user",
        "Change project priority",
      ],
    },
    {
      value: "DeleteProject",
      details: ["Delete project"],
    },
    {
      value: "DownloadProject",
      details: ["Download project"],
    },
  ],
};

const activitiesSectionInfo = {
  id: "activities",
  sectionTitle: "Activities",
  attributes: [
    {
      value: "PreviewActivities",
      details: ["Preview project activities", "Preview activity users"],
    },
    {
      value: "ViewActivityDashboard",
      details: ["Preview activity dashboard"],
    },
    {
      value: "CreateActivity",
      details: ["Create new activity"],
    },
    {
      value: "UpdateActivity",
      details: [
        "Edit activity",
        "Add user to activity",
        "Edit activity user",
        "Delete activity user",
      ],
    },
    {
      value: "DeleteActivity",
      details: ["Delete activity"],
    },
  ],
};

const surveysSectionInfo = {
  id: "surveys",
  sectionTitle: "Surveys",
  attributes: [
    {
      value: "PreviewSurveys",
      details: ["Preview activity surveys", "Preview survey information"],
    },
    // {
    //   value: "ViewSurveyDashboard",
    //   details: ["Preview survey dashboard"],
    // },
    {
      value: "CreateSurvey",
      details: ["Create new survey"],
    },
    {
      value: "UpdateSurvey",
      details: [
        "Edit survey information",
        "Edit survey design",
        "Activate survey",
      ],
    },
    {
      value: "DeleteSurvey",
      details: ["Delete survey"],
    },
    {
      value: "ExportSurveyDesignAsExcel",
      details: ["Export survey as Excel file"],
    },
    {
      value: "ImportSurveyDesignFromExcel",
      details: ["Import survey from Excel file"],
    },
    {
      value: "ExportSurveyData",
      details: ["Export survey data"],
    },
    {
      value: "ViewEnumeratorsInformation",
      details: ["View enumerators information"],
    },
    {
      value: "EnableDisableAnonymousDataCollection",
      details: ["Enable/Disable anonymous data collection"],
    },
  ],
};

const tasksSectionInfo = {
  id: "tasks",
  sectionTitle: "Tasks",
  attributes: [
    {
      value: "PreviewTasks",
      details: ["Preview tasks"],
    },
    {
      value: "CreateTask",
      details: ["Create new task"],
    },
    {
      value: "UpdateTask",
      details: ["Edit task"],
    },
    {
      value: "DeleteTasks",
      details: ["Delete task"],
    },
    {
      value: "TaskForceRetrieve",
      details: ["Activate force retrieve button"],
    },
  ],
};

const submissionsSectionInfo = {
  id: "submissions",
  sectionTitle: "Submissions",
  attributes: [
    {
      value: "PreviewSubmissions",
      details: ["Preview submissions"],
    },
    {
      value: "EditSubmission",
      details: ["Edit submission"],
    },
    {
      value: "ChangeInterviewStatus",
      details: ["Accept/Reject submission"],
    },
    {
      value: "DeleteSubmission",
      details: ["Delete submission"],
    },
  ],
};

const viewsSectionInfo = {
  id: "views",
  sectionTitle: "Views",
  attributes: [
    {
      value: "CreateView",
      details: ["Create new view"],
    },
    {
      value: "UpdateView",
      details: ["Edit view"],
    },
    {
      value: "DeleteView",
      details: ["Delete view"],
    },
    {
      value: "ArchiveView",
      details: ["Archive view"],
    },
    {
      value: "ShareView",
      details: ["Share view"],
    },
    {
      value: "ExportView",
      details: ["Export view reports"],
    },
  ],
};

const auditsSectionInfo = {
  id: "audits",
  sectionTitle: "Audits Log",
  attributes: [
    {
      value: "PreviewAudits",
      details: ["Preview audits"],
    },
    {
      value: "ArchiveAudits",
      details: ["Archive audits"],
    },
    {
      value: "UndoAction",
      details: ["Undo action"],
    },
  ],
};

const rolesSectionInfo = {
  id: "rolesManagement",
  sectionTitle: "Roles Management",
  attributes: [
    {
      value: "PreviewRolesDetails",
      details: ["Preview roles"],
    },
    {
      value: "CreateRole",
      details: ["Create new role"],
    },
    {
      value: "UpdateRole",
      details: ["Edit role", "Update role users"],
    },
    {
      value: "PermissionsManagement",
      details: ["Update role permissions"],
    },
    {
      value: "DeleteRole",
      details: ["Delete role"],
    },
  ],
};

const usersSectionInfo = {
  id: "usersManagement",
  sectionTitle: "Users Management",
  attributes: [
    {
      value: "PreviewUsersDetails",
      details: ["Preview users"],
    },
    {
      value: "CreateUser",
      details: ["Add new user"],
    },
    {
      value: "UpdateUsers",
      details: [
        "Edit user",
        "Add roles to user",
        "Change user account status",
        "Reset user password",
      ],
    },
    {
      value: "DeleteAccount",
      details: ["Delete user account"],
    },
    {
      value: "UnlockAccount",
      details: ["Unlock user account"],
    },
  ],
};

const criteriaSectionInfo = {
  id: "criteria",
  sectionTitle: "Project/Activity Criteria",
  attributes: [
    {
      value: "PreviewCriteria",
      details: ["Preview criteria"],
    },
    {
      value: "CreateCriteria",
      details: ["Create new criteria"],
    },
    {
      value: "UpdateCriteria",
      details: ["Edit criteria"],
    },
    {
      value: "DeleteCriteria",
      details: ["Delete criteria"],
    },
  ],
};

const campaignsManagementSectionInfo = {
  id: "campaignsManagement",
  sectionTitle: "Campaigns Management",
  attributes: [
    {
      value: "CampaignsManagement",
      details: ["Campaigns Management"],
    },
  ],
};

const entityTypesManagementSectionInfo = {
  id: "entityTypesManagement",
  sectionTitle: "Entity types Management",
  attributes: [
    {
      value: "PreviewEntityTypes",
      details: ["Preview entity types"],
    },
    {
      value: "SaveEntityType",
      details: ["Create new entity type", "Edit entity type"],
    },
    {
      value: "DeleteEntityType",
      details: ["Delete entity type"],
    },
  ],
};

const entityTypeRecordsManagementSectionInfo = {
  id: "entityTypeRecordsManagement",
  sectionTitle: "Entity type records Management",
  attributes: [
    {
      value: "PreviewEntityRecords",
      details: ["Preview entity records"],
    },
    {
      value: "SaveEntityRecord",
      details: ["Create new entity record", "Edit entity record"],
    },
    {
      value: "DeleteEntityRecord",
      details: ["Delete entity record"],
    },
  ],
};

/**
 * @constant {Object[]}
 * @description Permissions information for all sections of the dashboard
 */
export const PERMISSIONS_DATA = [
  generalSectionInfo,
  projectsSectionInfo,
  activitiesSectionInfo,
  surveysSectionInfo,
  criteriaSectionInfo,
  tasksSectionInfo,
  submissionsSectionInfo,
  viewsSectionInfo,
  auditsSectionInfo,
  rolesSectionInfo,
  usersSectionInfo,
  campaignsManagementSectionInfo,
  entityTypesManagementSectionInfo,
  entityTypeRecordsManagementSectionInfo,
];

/**
 * Generate user permissoins index after loggedIn or reloding the app
 * @function generateUserPermissionsObject
 * @param {string[]} loadedUserPermissions
 * @param {boolean} isAdmin
 * @returns {void}
 */
export function generateUserPermissionsObject(loadedUserPermissions, isAdmin) {
  if (!loadedUserPermissions) {
    throw Error(
      "Loaded user permissions not found! - generateUserPermissionsObject"
    );
  }

  const frontUserPermissions = [];
  PERMISSIONS_DATA.forEach(permission => {
    permission.attributes.forEach(attribute => {
      const permissionKey = attribute.value;
      if (isAdmin || loadedUserPermissions?.includes(attribute.value)) {
        frontUserPermissions.push(permissionKey);
      }
    });
  });
  return frontUserPermissions;
}
