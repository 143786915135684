/**
 * Checks if a given language code is valid.
 * @function checkLanguageCodeValidity
 * @param {string} langCode - The language code to be validated.
 * @returns {boolean} - True if the language code is valid, false otherwise.
 */
export default function checkLanguageCodeValidity(langCode) {
  if (!langCode) return false;
  const isValid = /^[a-z]{2}$/.test(langCode);
  return isValid;
}
