import { Switch, Route, Redirect } from "react-router-dom";
import { lazy } from "react";
import { useTenantsAuth } from "./tenantsManagementStore/TenantsAuthProvider";
import IdleAdminPrompt from "./components/TenantsManagementComponents/IdleAdminPrompt";
import LayoutContainer from "./components/TenantsManagementComponents/LayoutContainer/LayoutContainer";
import "tippy.js/dist/tippy.css";
import "simplebar-react/dist/simplebar.min.css";

const TenantsAdminLogin = lazy(
  () => import("./pages/tenantsManagement/TenantsAdminLogin")
);
const TenantsPreview = lazy(
  () => import("./pages/tenantsManagement/TenantsPreview")
);
const ContractsPreview = lazy(
  () => import("./pages/tenantsManagement/ContractsPreview")
);
const BundlesPreview = lazy(
  () => import("./pages/tenantsManagement/BundlesPreview")
);
const RequestsPreview = lazy(
  () => import("./pages/tenantsManagement/RequestsPreview")
);
const BundlesManagement = lazy(
  () => import("./pages/tenantsManagement/BundlesManagement")
);
const ContractsManagement = lazy(
  () => import("./pages/tenantsManagement/ContractsManagement")
);
const PageNotFound = lazy(() => import("./pages/general/PageNotFound"));
const AccessDenied = lazy(() => import("./pages/general/AccessDenied"));

function TenantsManagementApp() {
  const loginURL = "/login";
  const mainPageURL = "/subscribers";

  const { isLoggedIn } = useTenantsAuth();

  return (
    <>
      <Switch>
        <Route path="/access-denied" exact>
          <AccessDenied />
        </Route>
        <Route path={loginURL} exact>
          {isLoggedIn && <Redirect to={mainPageURL} />}
          {!isLoggedIn && <TenantsAdminLogin />}
        </Route>
        <LayoutContainer>
          <Route path="/" exact>
            {isLoggedIn && <Redirect to={mainPageURL} />}
            {!isLoggedIn && <Redirect to={loginURL} />}
          </Route>
          <Route path="/subscribers" exact>
            {isLoggedIn && <TenantsPreview />}
            {!isLoggedIn && <Redirect to={loginURL} />}
          </Route>
          <Route path="/subscriber/contracts" exact>
            {isLoggedIn && <ContractsPreview />}
            {!isLoggedIn && <Redirect to={loginURL} />}
          </Route>
          <Route path="/subscriber/bundles" exact>
            {isLoggedIn && <BundlesPreview />}
            {!isLoggedIn && <Redirect to={loginURL} />}
          </Route>
          <Route path="/requests" exact>
            {isLoggedIn && <RequestsPreview />}
            {!isLoggedIn && <Redirect to={loginURL} />}
          </Route>
          <Route path="/contracts-management" exact>
            {isLoggedIn && <ContractsManagement />}
            {!isLoggedIn && <Redirect to={loginURL} />}
          </Route>
          <Route path="/bundles-management" exact>
            {isLoggedIn && <BundlesManagement />}
            {!isLoggedIn && <Redirect to={loginURL} />}
          </Route>
        </LayoutContainer>
        <Route path="*">
          {isLoggedIn && <PageNotFound />}
          {!isLoggedIn && <Redirect to={loginURL} />}
        </Route>
      </Switch>
      {isLoggedIn && <IdleAdminPrompt />}
    </>
  );
}

export default TenantsManagementApp;
