import i18next from "i18next";
import Swal from "sweetalert2";

/**
 * Shows a warning alert popup modal
 * @function showWarningMessage
 * @async
 * @param {string} title - Alert title
 * @param {string} message - Alert message
 * @param {Function} [confirmedFunction] - Execute it if it has passed and the alert has confirmed
 */
export default async function showWarningMessage(
  title,
  message,
  confirmedFunction
) {
  const t = i18next.t;
  let { isConfirmed } = await Swal.fire({
    titleText: title || t("action_not_completed"),
    html: message,
    icon: "warning",
    iconColor: "#ffcc00",
    confirmButtonColor: "#333",
    confirmButtonText: t("ok"),
    customClass: {
      icon: "animate-pulse",
    },
  });

  if (isConfirmed && confirmedFunction) {
    confirmedFunction();
  }
}
