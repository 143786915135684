function LogoShape({ classes }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      className={classes || "w-32"}
    >
      <path
        className="fill-[#171717] dark:fill-dark-main-white"
        d="M114.05,91.71A8.94,8.94,0,1,1,123,82.77,8.95,8.95,0,0,1,114.05,91.71Zm0-16.45a7.51,7.51,0,1,0,7.51,7.51A7.51,7.51,0,0,0,114.05,75.26Z"
      />
      <path
        className="fill-[#171717] dark:fill-dark-main-white"
        d="M114.05,182.16a8.94,8.94,0,1,1,8.93-8.93A8.94,8.94,0,0,1,114.05,182.16Zm0-16.44a7.51,7.51,0,1,0,7.51,7.51A7.51,7.51,0,0,0,114.05,165.72Z"
      />
      <path
        className="fill-[#171717] dark:fill-dark-main-white"
        d="M137.1,114.32a8.79,8.79,0,0,1-4-1,27.45,27.45,0,0,0-5.05-1.94,38.7,38.7,0,0,0-9.35-1.68,8.77,8.77,0,0,0-.9,0,6.72,6.72,0,0,0-4.09,2,8.94,8.94,0,0,1-12.88-.45,8.94,8.94,0,0,1,5.83-14.86,8.88,8.88,0,0,1,7.32,2.76,5.79,5.79,0,0,0,3.93,1.92h.37a34.92,34.92,0,0,0,9.2-1.51,31.13,31.13,0,0,0,5.69-2.29,8.93,8.93,0,0,1,3.83-.93h.14a9.07,9.07,0,0,1,8.94,8.68,8.93,8.93,0,0,1-8.93,9.19Zm-18.45-6a39.82,39.82,0,0,1,9.73,1.74,28.4,28.4,0,0,1,5.32,2,7.5,7.5,0,0,0,10.9-6.91A7.59,7.59,0,0,0,137,97.88h0a7.49,7.49,0,0,0-3.22.78,32.54,32.54,0,0,1-5.94,2.39,36.55,36.55,0,0,1-9.6,1.56l-.4,0a7.14,7.14,0,0,1-4.91-2.36,7.49,7.49,0,0,0-6.16-2.32,7.6,7.6,0,0,0-6.76,6.62,7.42,7.42,0,0,0,1.87,5.86,7.47,7.47,0,0,0,10.81.38,8.1,8.1,0,0,1,5-2.43C118,108.32,118.31,108.3,118.65,108.3Z"
      />
      <path
        className="fill-[#171717] dark:fill-dark-main-white"
        d="M137.1,159.55a8.75,8.75,0,0,1-4.1-1,25.93,25.93,0,0,0-4.95-1.9,38.79,38.79,0,0,0-9.4-1.69q-.47,0-.9,0a6.73,6.73,0,0,0-4.09,2,8.94,8.94,0,1,1,.27-12.55,5.83,5.83,0,0,0,3.93,1.92h.37a35.37,35.37,0,0,0,9.18-1.5,31.55,31.55,0,0,0,5.72-2.31,8.88,8.88,0,0,1,3.82-.92h.14a9.07,9.07,0,0,1,8.94,8.68,8.93,8.93,0,0,1-8.93,9.19Zm-18.45-6a39.85,39.85,0,0,1,9.78,1.75,26.63,26.63,0,0,1,5.23,2,7.5,7.5,0,0,0,10.94-6.89,7.63,7.63,0,0,0-7.51-7.3H137a7.39,7.39,0,0,0-3.2.77,33,33,0,0,1-6,2.4,36.23,36.23,0,0,1-9.58,1.55h-.4a7.22,7.22,0,0,1-4.91-2.36,7.49,7.49,0,0,0-6.16-2.32,7.59,7.59,0,0,0-6.76,6.62,7.42,7.42,0,0,0,1.87,5.86,7.49,7.49,0,0,0,10.81.38,8.1,8.1,0,0,1,5-2.43C118,153.54,118.31,153.53,118.65,153.53Z"
      />
      <path
        className="fill-[#171717] dark:fill-dark-main-white"
        d="M148.56,136.94a8.64,8.64,0,0,1-2.37-.33l-1.08-.3c-1-.28-2-.57-2.93-.78-9.59-2.05-16.51-3.18-19.51-3.18a21.72,21.72,0,0,0-5.67.91,16.87,16.87,0,0,0-4.73,2.26,8.91,8.91,0,0,1-10.94-14,8.86,8.86,0,0,1,11.31-.79,14.11,14.11,0,0,0,4.77,2.37,21.48,21.48,0,0,0,4.85.68c4.61,0,14.67-2,20.24-3.31,1-.22,1.89-.52,2.87-.84l.46-.15a8.82,8.82,0,0,1,4.39-.27h0a8.94,8.94,0,0,1,7.22,7.8,9,9,0,0,1-8.88,9.92Zm-25.89-6c4,0,13,1.75,19.81,3.22,1,.22,2,.51,3,.79l1.06.31a7.59,7.59,0,0,0,7.57-2.22,7.44,7.44,0,0,0,1.88-5.85,7.52,7.52,0,0,0-6.06-6.55h0a7.55,7.55,0,0,0-3.69.23l-.46.15c-1,.31-2,.64-3,.87-6.88,1.57-16,3.39-20.59,3.35a22.55,22.55,0,0,1-5.16-.73,15.2,15.2,0,0,1-5.26-2.6,7.43,7.43,0,0,0-9.5.68,7.49,7.49,0,0,0,9.18,11.75,18.7,18.7,0,0,1,5.13-2.44A23.24,23.24,0,0,1,122.67,130.92Z"
      />
      <circle className="fill-[#2f85c7]" cx="137.1" cy="105.39" r="4.69" />
      <circle className="fill-[#2f85c7]" cx="107.44" cy="128" r="4.69" />
      <circle className="fill-[#2f85c7]" cx="137.87" cy="150.61" r="4.69" />
    </svg>
  );
}

export default LogoShape;
