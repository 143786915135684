import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import AuthInputControl from "../../UserAuthenticationFormControls/AuthInputControl";
import PasswordInputControl from "../../UserAuthenticationFormControls/PasswordInputControl";
import { useTranslation } from "react-i18next";

function LoginFormControls() {
  const { t } = useTranslation();

  return (
    <div className="my-5 w-full flex flex-col gap-3">
      <AuthInputControl
        inputType="text"
        placeholderText={t("login_key")}
        registerName="email"
        validationRules={{
          required: t("required_message"),
        }}
      >
        <FontAwesomeIcon
          icon={faEnvelope}
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-primary ltr:left-3 rtl:right-3"
        />
      </AuthInputControl>

      <PasswordInputControl
        inputType="password"
        placeholderText={t("password")}
        registerName="password"
        validationRules={{
          required: "required_message",
          validate: {
            notEmpty: v => v.trim().length > 0 || "required_message",
          },
        }}
      >
        <FontAwesomeIcon
          icon={faKey}
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-primary ltr:left-3 rtl:right-3"
        />
      </PasswordInputControl>

      {/* <RememberMeCheckbox registerName="rememberMe" /> */}
    </div>
  );
}

export default LoginFormControls;
