import isSecondDateError from "./isSecondDateError";

/**
 * Check if an UTC date has already expired.
 * @function isExpiredDate
 * This function checks if a given date has already expired by comparing it with the current date. It returns a boolean indicating whether the date has expired or not. If the date is not provided, the function returns `false`.
 * @param {string} utcDate - The date to be checked in ISO string format.
 * @returns {boolean} - Returns `true` if the date has expired; otherwise, returns `false`. If the date is not provided, the function returns `false`.
 */

export function isExpiredDate(utcDate) {
  if (!utcDate) return false;
  const dateNow = new Date();
  const utcDateNow = dateNow.toISOString();
  const isExpired = isSecondDateError(utcDateNow, utcDate);
  return isExpired;
}
